<template>
  <main>
    <v-card flat>
      <v-card-title>
        <div>
          Full Offers List

          <v-btn icon class="ml-3" @click="getOffers" :disabled="!isAuthenticated || offers.loading"><v-icon>{{ icons.mdiReload }}</v-icon></v-btn>
        </div>
      </v-card-title>

      <v-card-text>
        <v-data-table
          :headers="tableColumns"
          :footer-props="{
            'items-per-page-options': [10, 10],
            'disable-items-per-page': true,
            'disable-pagination': offers.loading
          }"
          :items="offers.list"
          :options.sync="offers.options"
          :page="offers.meta.page"
          :server-items-length="offers.meta.total || 0"
          :loading="offers.loading"
          @pagination="changePagination($event)"
          :no-data-text="'No data available'"
          :loading-text="'Loading, pls wait'"
          class="text-no-wrap"
        >
          <template #[`item.createdAt`]="{item}">
            {{ formatDate(item.createdAt) }}
          </template>

          <template #[`item.budget`]="{item}">
            {{getOfferBudget(item)}}
          </template>

          <template #[`item.endDate`]="{item}">
            {{ formatEndDate(item) }}
          </template>

          <template #[`item.payout`]="{item}">
            {{ item.payout }} {{ item.token }}
          </template>

          <template #[`item.actions`]="{item}">
            <v-menu
              bottom
              left
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn small icon v-bind="attrs" v-on="on">
                  <v-icon size="18">
                    {{ icons.mdiDotsVertical }}
                  </v-icon>
                </v-btn>
              </template>

              <v-list>
                <v-list-item link dense @click="editOffer(item)">
                  <v-list-item-title>
                    <v-icon size="18" class="me-2">{{ icons.mdiFileEdit }}</v-icon>
                    <span>Edit Offer</span>
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </main>
</template>

<script>
import { mdiReload, mdiFileEdit, mdiDotsVertical } from '@mdi/js'
import { mapGetters } from 'vuex'
import moment from 'moment'

import { eventBus } from '@/utils/eventBus'

export default {
  data: () => ({
    icons: { mdiReload, mdiFileEdit, mdiDotsVertical },
    offers: {
      loading: false,
      docs: [],
      meta: {},
      options: {
        sortBy: ['id'],
        sortDesc: [true],
        itemsPerPage: 10,
      },
    },
  }),
  computed: {
    ...mapGetters('web3auth', ['isAuthenticated', 'userdata']),
    tableColumns() {
      return [
        { text: 'PROJECT', value: 'project.name', sortable: false },
        { text: 'NAME', value: 'name', sortable: false },
        { text: 'TYPE', value: 'type', sortable: false },
        { text: 'PAYOUT', value: 'payout', sortable: false },
        { text: 'CHAIN', value: 'blockchain', sortable: false },
        { text: 'BUDGET', value: 'budget', sortable: false },
        { text: 'STATUS', value: 'status', sortable: false },
        { text: 'ACTIONS', value: 'actions', sortable: false },
      ]
    },
  },
  created() {
    eventBus.$on('offer-builder-update', () => {
      this.getOffers({ page: 1 })
    })
  },
  methods: {
    getOffers(query) {
      if (!this.isAuthenticated) return

      this.offers.loading = true

      const params = {
        limit: this.offers.options.itemsPerPage,
        query: JSON.stringify(query),
        sort: { createdAt: 'desc' },
        ...params,
      }

      this.$http
        .get('admin/offers', { params })
        .then(({ data }) => {
          const { meta, docs } = data

          this.offers.meta = meta
          this.offers.list = docs
        })
        .catch(err => {
          console.log(err)
        })
        .finally(() => {
          this.$nextTick(() => {
            this.offers.loading = false
          })
        })
    },

    changePagination(pagination) {
      this.getOffers({
        page: pagination.page,
      })
    },

    editOffer(item) {
      this.$store.commit('modal/SET_OPTIONS', {
        show: true,
        type: 'offer-builder',
        modalAttrs: {
          persistent: true,
          'max-width': 1200,
        },
        componentAttrs: {
          offerData: JSON.parse(JSON.stringify(item)),
        },
      })
    },

    getOfferBudget(item) {
      if (item.status !== 'onchain') {
        return 'Not Specified'
      }

      const budget = item.web3data?.budget

      return budget ? `${budget} ${item.token}` : `-`
    },

    formatDate(date) {
      return moment(date).format('MMMM Do YYYY, h:mm:ss a')
    },
  },
}
</script>

